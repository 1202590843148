import React from 'react'
import Code from '../Components/Code'
import { COMPONENT_TYPES } from '../../../../../../store/selectors/jrPlusState/engineering'
import styled from 'styled-components'
import type { CodeData as CodeComponent } from '@mission.io/mission-toolkit'
import type { PopulatedPanel } from '../../../../../../store/selectors/jrPlusState/engineering'

const StyledCodePanel = styled.div`
	width: 100%;
`

export default function CodePanel({ panel }: { panel: PopulatedPanel }): React$Node {
	let codeComponent: ?CodeComponent = null
	panel.components.forEach(component => {
		if (component.type === COMPONENT_TYPES.CODE) {
			codeComponent = component
		}
	})
	return (
		<StyledCodePanel>
			{codeComponent ? (
				<Code component={codeComponent} />
			) : (
				`ERROR: no component of type ${COMPONENT_TYPES.CODE}`
			)}
		</StyledCodePanel>
	)
}
