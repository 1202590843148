import type { StyledType } from '../../types/utilityTypes'

import {
	STATION_ICON_Z_INDEX,
	EMPTY_SPACE,
	rgba,
	ACCENT_GREEN,
	ACCENT_BLUE,
	PRIMARY_GREEN,
} from '../../constants/styles'
import styled, { css, keyframes } from 'styled-components'

const MAX_GROW = 1.2
const MIN_GROW = 1

const glow = keyframes`
from {
  box-shadow: 0 0 5px ${ACCENT_GREEN}, 0 0 15px ${ACCENT_GREEN}, 0 0 25px ${PRIMARY_GREEN};
}
to {
  box-shadow: 0 0 10px ${PRIMARY_GREEN}, 0 0 30px ${ACCENT_BLUE}, 0 0 40px ${ACCENT_BLUE};
}
`

const grow = keyframes`
0% {
	transform: scale(${MIN_GROW});
}
100% {
	transform: scale(${MAX_GROW});
}
`

const StationIcon: StyledType<> = styled.div`
	cursor: pointer;
	position: relative;
	width: 6vh;
	height: 6vh;
	text-align: center;
	position: relative;
	svg {
		height: 100%;
		width: 100%;
	}
	border-radius: 100vw;
	z-index: ${STATION_ICON_Z_INDEX};
	${({ $isCurrent, $active }) =>
		$isCurrent
			? css`
					animation: ${glow} 2s ease-in-out infinite alternate;
			  `
			: $active
			? css`
					box-shadow: 0 0 10px ${ACCENT_BLUE};
					animation: ${grow} 500ms ease-in-out infinite alternate;
			  `
			: ''};
`

const StationName: StyledType<> = styled.div`
	position: absolute;
	opacity: 0;
	transition: opacity 300ms;
	transition-timing-function: linear;
	right: 100%;
	padding: 4px;
	border-radius: 4px;
	top: 0;
	transform: translateY(50%);
	font-size: 1em;
	width: fit-content;
	color: white;
	background-color: ${rgba(EMPTY_SPACE, 0.6)};
	z-index: ${STATION_ICON_Z_INDEX};
	white-space: nowrap;
	overflow: hidden;

	${StationIcon}: hover & {
		opacity: 1;
	}
`

export { StationName, StationIcon }
