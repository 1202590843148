import React from 'react'
import { ReactModal as Modal } from '../../basics'
import 'styled-components/macro'
import { ExpandIcon } from '../../CreativeCanvas/components/CanvasGrid'
import { LITERACY_EVENT_Z_INDEX } from '../../../constants/styles'

/**
 * A component that displays an icon in the bottom right corner of its next relative ancestor.
 * When clicked, it will open a modal with the children passed to it.
 * @param {React$Node} props.children the content to display in the modal.
 * @param {string|void} props.className the class name to apply to the icon.
 * @returns {React$Node}
 */
export function ContentExpander({
	children,
	className,
}: {
	children: React$Node,
	className?: string,
}): React$Node {
	const [expanded, setExpanded] = React.useState(false)

	return (
		<>
			<ExpandIcon
				className={className}
				css="width: 32px;"
				onClick={() => {
					setExpanded(state => !state)
				}}
				expanded={expanded}
			/>

			{expanded && (
				<Modal
					frameContent
					onRequestClose={() => setExpanded(false)}
					style={ModalStyles}
					isOpen={true}>
					<div className="[&_img]:rounded-xl">{children}</div>
				</Modal>
			)}
		</>
	)
}

const ModalStyles = {
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.6)',
		zIndex: LITERACY_EVENT_Z_INDEX,
	},
	content: {
		border: 'none',
		backgroundColor: 'transparent',
		overflow: 'hidden',
		padding: '5px',
		inset: '50% auto auto 50%',
	},
}
