import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
	PANEL_TYPES,
	getCurrentStudentPanels,
} from '../../../../../store/selectors/jrPlusState/engineering'
import CodePanel from './Panels/Code'
import ButtonPanel from './Panels/Buttons'
import SliderPanel from './Panels/Sliders'
import SwitchPanel from './Panels/Switch'
import ConnectionPanel from './Panels/Connection'
import TeamSwitchPanel from './Panels/TeamSwitch'
import TeamSwitchOverlay from './Overlays/TeamAnimation'

const StyledPanel = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
`

const StyledPanelSpace = styled.div`
	height: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
	position: relative;
`

const PanelWrapper = styled.div`
	width: 100%;

	> * {
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
`

const PANEL_TYPES_TO_COMPONENT = {
	[PANEL_TYPES.CODE]: CodePanel,
	[PANEL_TYPES.SWITCH]: SwitchPanel,
	[PANEL_TYPES.SLIDER]: SliderPanel,
	[PANEL_TYPES.BUTTON]: ButtonPanel,
	[PANEL_TYPES.CONNECTION]: ConnectionPanel,
	[PANEL_TYPES.TEAM_SWITCH]: TeamSwitchPanel,
}

const PANEL_TYPES_TO_OVERLAYS = {
	[PANEL_TYPES.TEAM_SWITCH]: TeamSwitchOverlay,
}

export default function Panel(): React$Node {
	const panels = useSelector(getCurrentStudentPanels)
	const renderedPanels = []
	const renderedOverlays = []
	panels.forEach(panel => {
		if (PANEL_TYPES_TO_COMPONENT[panel.type]) {
			const Component = PANEL_TYPES_TO_COMPONENT[panel.type]
			renderedPanels.push(<Component panel={panel} key={panel.id} />)
		}
		if (PANEL_TYPES_TO_OVERLAYS[panel.type]) {
			const Overlay = PANEL_TYPES_TO_OVERLAYS[panel.type]
			renderedOverlays.push(<Overlay panel={panel} key={`overlay-${panel.id}`} />)
		}
	})
	return (
		<StyledPanelSpace>
			<StyledPanel className="flex flex-col justify-center w-fit">
				<PanelWrapper className="engPanels">{renderedPanels}</PanelWrapper>
			</StyledPanel>
			{renderedOverlays}
		</StyledPanelSpace>
	)
}
