import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import RangeSlider from 'react-rangeslider'
// $FlowIgnore[cannot-resolve-module] this module exists because react-rangeslider exists
import 'react-rangeslider/lib/index.css'
import { getSliderSocketMessage } from '../../../../../../store/selectors/jrPlusState/engineering'
import { sendMessage } from '../../../../../../store/stores/webSocket'
import { BACKGROUND_BLUE, FOREGROUND_BLUE } from '../SharedColors'

import type { SliderData as SliderComponent } from '@mission.io/mission-toolkit'

const StyledSlider = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	height: 15em;
	font-size: ${({ theme }) => theme.fontxxxs};
	font-family: ${({ theme }) => theme.fontFamily};

	.engineering-slider {
		flex: 1 1;
		margin: 20px 8px;
		background-color: ${BACKGROUND_BLUE};
		border-radius: 1em;

		.rangeslider__handle {
			background-color: white;
			height: 1.5em;
			width: 1.5em;
			border-radius: 50%;
			left: -0.33em;
			z-index: 1;
			${({ $showHandleLabel, theme }) =>
				$showHandleLabel &&
				`.rangeslider__handle-label {
				font-size: ${theme.fontxxxs};
				font-family: Orbitron;
				transform: translate(-2em);
			}`}
		}

		.rangeslider__label-item {
			font-size: ${({ theme }) => theme.fontxxxs};
			font-family: Orbitron;
			transform: translate(-1.5em);
			:before {
				display: none;
			}
		}

		.rangeslider__fill {
			background-color: ${FOREGROUND_BLUE};
			border-radius: 0 0 1em 1em;
		}
	}
`

export default function Slider({
	component,
	horizontal,
}: {
	component: SliderComponent,
	horizontal?: boolean,
}): React$Node {
	const [value, setValue] = useState(component.currentValue)
	const [isChanging, setIsChanging] = useState(false)
	const dispatch = useDispatch()
	const step = (component.maxValue - component.minValue) / component.steps
	return (
		<StyledSlider $showHandleLabel={component.maxValue - component.minValue > MAX_STEPS_TO_LABEL}>
			<RangeSlider
				min={component.minValue}
				max={component.maxValue}
				step={step}
				value={value}
				className="engineering-slider notranslate"
				orientation={horizontal ? 'horizontal' : 'vertical'}
				onChange={value => setValue(value)}
				handleLabel={(isChanging && String(Math.round(value))) || ''}
				onChangeStart={() => setIsChanging(true)}
				onChangeComplete={() => {
					dispatch(
						sendMessage('ENGINEERING_PANEL_ACTION', getSliderSocketMessage(component.id, value))
					)
					setIsChanging(false)
				}}
				tooltip={false}
				labels={generateLabels(component.minValue, component.maxValue, step)}
			/>
			{component.name}
		</StyledSlider>
	)
}

const MAX_STEPS_TO_LABEL = 4

function generateLabels(min: number, max: number, step: number) {
	if (max - min <= MAX_STEPS_TO_LABEL) {
		const labels = {}
		for (let s = min; s <= max; s += step) {
			labels[s] = s
		}
		return labels
	}
	return {
		[min]: min,
		[max]: max,
	}
}
