import React, { useState } from 'react'
import styled from 'styled-components'
import type { HealthTaskInfo } from '@mission.io/mission-toolkit'
import { insertName } from '../helpers'
import { random } from '../../../utility/functions'
import TextToSpeech from '../../TextToSpeech'

const NUM_EXAMPLES = 3

function getExamples(taskInfo: HealthTaskInfo): string[] {
	if (taskInfo.selectRandomFromExamples) {
		const examples = []
		const available = [...taskInfo.examples]
		for (let i = 0; i < NUM_EXAMPLES; i++) {
			const index = random(0, available.length - 1)
			const removed = available.splice(index, 1)
			examples.push(removed[0])
		}
		return examples
	}
	return taskInfo.examples
}

const StyledExamples = styled.ul`
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 6px;
	padding: 12px;
	li {
		list-style-type: none;
		color: white;

		padding-bottom: 5px;
	}
`

export default function Example({
	taskInfo,
	partnerName,
}: {
	taskInfo: HealthTaskInfo,
	partnerName: string,
}): React$Node {
	const [examples] = useState(getExamples(taskInfo))
	return (
		<StyledExamples>
			{examples
				? examples.map(example => (
						<li key={example}>
							<TextToSpeech>-{insertName(example, partnerName)}</TextToSpeech>
						</li>
				  ))
				: null}
		</StyledExamples>
	)
}
