import React from 'react'
import { STATUS_MESSAGE_RECONNECT_ATTEMPTS, getWebSocketStore } from '../store/stores/webSocket'
import { FaExclamationTriangle } from 'react-icons/fa'
import './ConnectionStatus.css'
import { useSelector } from 'react-redux'

export default function ConnectionStatus(): React$Node {
	const { isOpen, connectAttempts } = useSelector(getWebSocketStore)
	let message = ''
	let classNames = 'ConnectionStatus'

	if (connectAttempts >= STATUS_MESSAGE_RECONNECT_ATTEMPTS) {
		classNames += ' disconnected'
		message =
			'Station has lost contact with the ship. Attempting to reconnect. Please wait for instructions from your flight director.'
	}
	// else if (!isOpen) {
	// 	classNames += ' disconnected'
	// 	message = isOpening ? 'Attempting to connect...' : ('Disconnected! Attempted to reconnect ' + (connectAttempts + 1) + ' time(s).' )
	// }
	else {
		classNames += ' hidden'
	}

	return (
		<div className={classNames}>
			{!isOpen && <FaExclamationTriangle />}
			{message}
		</div>
	)
}
