import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { useSelector } from 'react-redux'
import { getMissionType } from './store/stores/general'
import { MISSION_TYPES } from '@mission.io/mission-toolkit/constants'

const GlobalStyle = createGlobalStyle`
    html, #Main-root {
        ${
					'' /* IPad "Fix"/hack - https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
				}
        max-height: -webkit-fill-available;
    }
    ${({ theme, fourPlus }) => `
        :root {
            --z-index-ui: ${theme.uiZIndex};
            --z-index-overlay: calc(var(--z-index-ui) + 1);

            --color-primary: ${theme.primary}; // tailwind color: primary
            --color-primary-dark: ${theme.darkPrimary}; // tailwind color: primary-600
            --color-primary-darker: ${theme.darkerPrimary}; // tailwind color: primary-700
            --color-primary-darkest: ${theme.darkestPrimary}; // tailwind color: primary-800
            --color-on-base: ${fourPlus ? theme.darkerPrimary : theme.darkestPrimary};
            --color-faded-on-base: ${fourPlus ? 'rgba(60,73,124,.6)' : 'rgba(21,21,64,.6)'};
            --color-danger: ${theme.error};
            --color-secondary: ${theme.secondary}; // closest tailwind color: primary-700

            --spacing-half: ${theme.spacingHalf};
            --spacing: ${theme.spacing};
            --spacing2x: ${theme.spacing2x};
            --spacing3x: ${theme.spacing3x};
            --spacing4x: ${theme.spacing4x};

            --title-font: 'Orbitron', sans-serif; 

            --font-size-xxxs: ${theme.fontxxxs};
            --font-size-xxs: ${theme.fontxxs};
            --font-size-xs: ${theme.fontxs};
            --font-size-s: ${theme.fonts};
            --font-size-m: ${theme.fontm};
            --font-size-xl: ${theme.fontxl};
            --font-size-xxl: ${theme.fontxxl};
            --font-size-xxxl: ${theme.fontxxxl};
        }
    `}

    .infinid-react-icons {
        vertical-align: middle;
    }
`

/**
 * Using styled-components theme and redux, this component applies global styles to the web page.
 *
 * This component must be wrapped in a Theme Provider and the app Redux Store Provider.
 * @returns {React$Node} Global Styles
 */
export default function GlobalStyleUsingState(): React$Node {
	const missionType = useSelector(getMissionType)

	return <GlobalStyle fourPlus={missionType === MISSION_TYPES.FOUR_PLUS} />
}
