import React from 'react'
import { useSelector } from 'react-redux'
import { ATOM_CONTAINER } from './EngineSVGLayout'
import { range } from '../../utility/functions'
import Atom from './Atom'
import styled from 'styled-components'

import {
	getMin,
	getMax,
	getCurrentAverage,
	isPowerActive,
} from '../../store/selectors/jrPlusState/power'

const ATOM_COUNT = 25

const colors = {
	overload: 'darkred',
	stable: 'green',
	underload: 'blue',
}

const Glass = styled.div`
	position: absolute;
	pointer-events: none;
	top: ${props => props.$y}px;
	left: ${props => props.$x}px;
	width: ${props => 2 * props.$radius}px;
	height: ${props => 2 * props.$radius}px;
	background-color: ${props => colors[props.$status]};
	transition: background-color 0.5s ease;
	overflow: hidden;
	border-radius: 100%;
	z-index: 1;
	box-shadow: 0px 0px 20px black inset;

	${props =>
		props.$status === 'overload'
			? 'animation: danger-pulse 0.5s linear 1s infinite alternate;'
			: ''}

	@keyframes danger-pulse {
		0% {
			transform: rotate(0);
			background-color: darkred;
		}
		100% {
			transform: rotate(3deg);
			background-color: red;
		}
	}
`

export default function AtomContainer({
	currentDimensions,
}: {
	currentDimensions: { x: number, y: number, width: number, height: number },
}): React$Node {
	const min = useSelector(getMin)
	const max = useSelector(getMax)
	const currentAverage = useSelector(getCurrentAverage)
	const active = useSelector(isPowerActive)

	return (
		<Glass
			$x={
				currentDimensions.x +
				currentDimensions.width * ATOM_CONTAINER.centerX -
				currentDimensions.width * ATOM_CONTAINER.radius
			}
			$y={
				currentDimensions.y +
				currentDimensions.height * ATOM_CONTAINER.centerY -
				currentDimensions.width * ATOM_CONTAINER.radius
			}
			$radius={currentDimensions.width * ATOM_CONTAINER.radius}
			$status={
				currentAverage < (min || 0) ? 'underload' : currentAverage > max ? 'overload' : 'stable'
			}>
			{active &&
				range(ATOM_COUNT).map(id => (
					<Atom
						key={id}
						radius={currentDimensions.width * ATOM_CONTAINER.radius}
						centerX={currentDimensions.width * ATOM_CONTAINER.radius}
						centerY={currentDimensions.width * ATOM_CONTAINER.radius}
						speed={Math.min(currentAverage, 1)}
					/>
				))}
		</Glass>
	)
}
