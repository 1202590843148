import type { LiteracyEventCloseReadingTask } from '@mission.io/mission-toolkit/actions'
import { CLOSE_READING, type StudentAnnotation } from '@mission.io/question-toolkit'

/**
 * Validates the student annotations for a close reading task and returns an error message if the annotations do not meet requirements.
 * This is meant to help the student succeed in submitting an annotation by alerting them to what they still need to do.
 * @param {LiteracyEventCloseReadingTask<string>} task The close reading task
 * @param {StudentAnnotation[]} annotations The student's annotations
 * @returns {string | null} An error message if the annotations do not meet requirements, or null if the annotations are valid
 */
export function validateStudentAnnotations(
	task: LiteracyEventCloseReadingTask<string>,
	annotations: StudentAnnotation[]
): ?string {
	// Check if the number of annotations is greater than the required number of annotations
	if (task.requiredAnnotations && task.requiredAnnotations > annotations.length) {
		const howManyMore = task.requiredAnnotations - annotations.length
		return `Mark up ${howManyMore} more thing${howManyMore > 1 ? 's' : ''}.`
	}

	// For each target annotation, check if the student has met the requirement
	const messages = task.targetAnnotations.reduce((errorMessages, target) => {
		if (target.required) {
			const annotationMeetsRequirement = annotations.some(
				annotation =>
					(target.style.color ? annotation.style.color === target.style.color : true) &&
					(target.style.tool ? annotation.style.tool === target.style.tool : true)
			)
			if (!annotationMeetsRequirement) {
				errorMessages.push(
					`${
						target.style.tool ? toolToHumanReadableText[target.style.tool] : 'mark'
					} something with ${target.style.color?.toLowerCase() || 'any color'}`
				)
			}
		}
		return errorMessages
	}, [])

	return messages.length > 0
		? `You still need to ${messages.join(', ').replace(/,([^,]*)$/, ' and$1')}.`
		: null
}

const toolToHumanReadableText = {
	[CLOSE_READING.ANNOTATION_TOOL.HIGHLIGHT]: 'highlight',
	[CLOSE_READING.ANNOTATION_TOOL.UNDERLINE]: 'underline',
	[CLOSE_READING.ANNOTATION_TOOL.STRIKE_THROUGH]: 'cross out',
}
