import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { switchStation } from '../../../../../store/stores/general'
import { STATION_ICON_Z_INDEX } from '../../../../../constants/styles'
import { getUnInteractedDataCount } from '../../../../../store/selectors/jrPlusState/sensor'
import { getActiveAlertTotal } from '../../../../../store/selectors/jrPlusState/decks'

import type { JrPlusStationEnum } from '../../../../../types'

type Props = {
	stationId: JrPlusStationEnum,
	isRemote: ?boolean,
}
export default function PushAlert({ stationId, isRemote }: Props): React$Node {
	const dispatch = useDispatch()
	const selector = stationId === 'decks' ? getActiveAlertTotal : getUnInteractedDataCount
	const alertTotal = useSelector(selector)
	if (alertTotal > 0) {
		return (
			<CircleAlert onClick={() => dispatch(switchStation(stationId))} $isRemote={isRemote}>
				<h3>{alertTotal}</h3>
			</CircleAlert>
		)
	}
	return null
}

const CircleAlert = styled.div`
	cursor: pointer;
	background-color: red;
	padding: 1vh;
	border-radius: 100%;
	position: absolute;
	z-index: ${STATION_ICON_Z_INDEX + 1};
	${({ $isRemote }) =>
		$isRemote
			? `
	top: -1.1vh;
	left: -1.1vw;`
			: `
	top: 4px;
	right: -4px
	`}
	color: white;
	text-align: center;
	line-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	h3 {
		margin: 0;
	}
	border: 0.5px solid black;
`
