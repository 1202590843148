// @flow
import React from 'react'
import { useState } from 'react'
import 'styled-components/macro'
import { Button } from '../../basics'
import Markdown from '../../Markdown'
import { useLiteracyEvent } from '../hooks'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import { validateStudentAnnotations } from './validateStudentAnnotations'

/**
 * A prompt that appears along with a close reading task, guiding a student to annotate a reading passage and submit their annotation.
 * @param {Function} onSubmit callback when an option is submitted
 * @returns {React$node}
 */
export function CloseReadingPrompt({
	onSubmit,
	taskText,
	submitButtonText = 'Done',
}: {
	onSubmit: () => void,
	taskText: string,
	submitButtonText?: string,
}): React$Node {
	const closeReadingTaskData = useLiteracyEvent()?.taskData
	const [warning, setWarning] = useState<string | null>(null)
	if (
		!closeReadingTaskData ||
		closeReadingTaskData.type !== LITERACY_EVENT['TASK']['TYPE']['CLOSE_READING']
	) {
		console.error('CloseReadingPrompt must be used with a close reading task')
		return null
	}

	return (
		<div className="flex flex-col h-full w-full px-5">
			<Markdown disabledComponents={['a', 'img']}>{taskText}</Markdown>
			<div className="mt-2 w-full justify-between flex align-top gap-2">
				{warning && <span className="italic text-white bg-error/45 rounded p-1"> {warning}</span>}
				<Button
					small
					className="!p-1 w-fit self-start ml-auto"
					onClick={e => {
						const errorMessage = validateStudentAnnotations(
							closeReadingTaskData.task,
							closeReadingTaskData.studentState.annotations
						)
						if (!errorMessage) {
							onSubmit()
						} else {
							setWarning(errorMessage)
						}
					}}>
					{submitButtonText}
				</Button>
			</div>
		</div>
	)
}
