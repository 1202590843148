import React from 'react'
import styled from 'styled-components'
import { BONUS_YELLOW, STATION_PADDING } from '../../constants/styles'
import 'styled-components/macro'
import { FRAME_WRAPPER_ID } from '../../components/basics/ReactModal'
import { useLineDrawingContext } from './LineDrawingContext'

const SVGWithNoClickEvents = styled.svg`
	pointer-events: none;
	position: absolute;
	margin: -${STATION_PADDING};
	height: 100%;
	width: 100%;
	z-index: 1;
`

export default function ConnectingCanvas(): React$Node {
	const { connectingIds } = useLineDrawingContext()
	const { startElementId: baseElemId, endElementId: targetElemId } = connectingIds
	if (!targetElemId || !baseElemId) return null
	const start = getPositionValues(baseElemId)
	const end = getPositionValues(targetElemId)
	if (!start || !end) return null
	const startY = start.top + 25
	const [endCenteredX, endCenteredY] = centerTarget([end.left, end.top], [end.width, end.height])

	return (
		<SVGWithNoClickEvents>
			<path
				d={`M ${start.left} ${startY} L ${endCenteredX} ${startY} L ${endCenteredX} ${endCenteredY}`}
				stroke={BONUS_YELLOW}
				fill="none"
				css="stroke-dasharray:4; stroke-width: 2;"
			/>
		</SVGWithNoClickEvents>
	)
}
/**
 * This function returns the values position for a given id.
 * Also, we altered the returned left value because the frame's
 * dimensions can be different for the student and teacher station.
 * @param {string} id
 * @returns the positionValues
 */
function getPositionValues(id: string) {
	const rect = getWrapperClientRect(id)
	const wrapper = getWrapperClientRect(FRAME_WRAPPER_ID)
	return rect
		? {
				left: rect.left - (wrapper?.left ?? 0),
				top: rect.top,
				width: rect.width,
				height: rect.height,
		  }
		: null
}

function getWrapperClientRect(id) {
	const el = document.getElementById(id)
	if (!el) return null
	const rect = el.getBoundingClientRect()

	return rect
}

export function centerTarget([x, y]: [number, number], size: [number, number]): [number, number] {
	return [x + size[0] / 2, y + size[1] / 2]
}
