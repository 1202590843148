import React, { useEffect, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
	EMPTY_SPACE,
	PROGRESS,
	PRIMARY_GREEN,
	ACCENT_BLUE,
	DARKEST_SHADE,
	REMOTE_STATUS_GREEN,
} from '../../constants/styles'
import {
	getTimer,
	isMissionPaused as select_isMissionPaused,
	NO_PROGRESS_TIMER,
} from '../../store/selectors/sharedSelectors'

const themes = {
	active: {
		pausedProgressColor: ACCENT_BLUE,
		pausedBackgroundColor: DARKEST_SHADE,
		progressColor: ACCENT_BLUE,
		backgroundColor: DARKEST_SHADE,
	},
	default: {
		pausedProgressColor: ACCENT_BLUE,
		pausedBackgroundColor: DARKEST_SHADE,
		progressColor: ACCENT_BLUE,
		backgroundColor: DARKEST_SHADE,
	},
	error: { progressColor: '#cb020e', backgroundColor: '#cc5a02' },
	success: { progressColor: PRIMARY_GREEN },
	remoteBoost: { progressColor: REMOTE_STATUS_GREEN },
}

export default function DeltaClockProgressBar({
	timerId,
	progressColor,
	pausedProgressColor,
	backgroundColor,
	pausedBackgroundColor,
	width,
	height,
	backwards,
	status,
	className,
	clearPreviousProgress,
	rectangular,
	includeText,
}: {
	timerId: ?string,
	progressColor?: string,
	pausedProgressColor?: string,
	backgroundColor?: string,
	pausedBackgroundColor?: string,
	width?: string,
	height?: string,
	backwards?: true,
	status?: 'error' | 'success' | 'default' | 'active' | 'remoteBoost',
	className?: string,
	clearPreviousProgress?: true,
	rectangular?: boolean,
	includeText?: boolean,
}): React$Node {
	const [reset, shouldReset] = useState(false)
	const isMissionPaused = useSelector(select_isMissionPaused)
	const { delayLeft, originalDelay, isPaused: isTimerPaused, currentProgress } =
		useSelector(state => getTimer(state, timerId)) || NO_PROGRESS_TIMER
	const isPaused = isMissionPaused || isTimerPaused
	let progress = currentProgress
	if (backwards) {
		progress = 1 - progress
	}
	const fromProgress = clearPreviousProgress ? (backwards ? 100 : 0) : progress * 100
	const spring = useSpring({
		to: { progress: backwards ? 0 : 100 },
		from: { progress: fromProgress },
		config: { duration: isPaused ? Infinity : delayLeft },
		reset: true,
	})

	useEffect(() => {
		shouldReset(true)
	}, [timerId, isPaused, originalDelay])
	useEffect(() => {
		if (reset) {
			shouldReset(false)
		}
	}, [reset])

	progressColor = (isPaused && pausedProgressColor) || progressColor || PROGRESS
	backgroundColor = (isPaused && pausedBackgroundColor) || backgroundColor || EMPTY_SPACE

	if (status && themes[status]) {
		let theme = themes[status]
		if (!isPaused) {
			progressColor = theme.progressColor || progressColor
			backgroundColor = theme.backgroundColor || backgroundColor
		} else {
			progressColor = theme.pausedProgressColor || theme.progressColor || progressColor
			backgroundColor = theme.pausedBackgroundColor || theme.backgroundColor || backgroundColor
		}
	}

	return (
		<StyledBackground
			className={className}
			$width={width}
			$height={height}
			$color={backgroundColor}
			$rectangular={rectangular}>
			<animated.div
				style={{
					width: spring.progress.to(progress => {
						return progress + '%'
					}),
					backgroundColor: progressColor,
					height: '100%',
				}}
			/>
			{includeText && (
				<animated.div
					className=" w-full -mt-2.5"
					style={{
						fontWeight: 'bold',
						// add a gradient to the text to make it change as the progress bar color fills up the dark background.
						backgroundImage: spring.progress.interpolate(
							p => `
							linear-gradient(to right, ${backgroundColor || EMPTY_SPACE} 0%,  ${backgroundColor ||
								EMPTY_SPACE} ${Math.round(p)}%, ${progressColor || PROGRESS} ${Math.round(
								p
							)}%, ${progressColor || PROGRESS} 100%)`
						),
						backgroundClip: 'text',
						color: 'transparent',
					}}>
					{spring.progress.interpolate(
						proportion =>
							`${proportion.toLocaleString(undefined, {
								maximumFractionDigits: 0,
							})}%`
					)}
				</animated.div>
			)}
		</StyledBackground>
	)
}

const StyledBackground = styled.div`
	background-color: ${props => props.$color || EMPTY_SPACE};
	${({ $rectangular }) =>
		$rectangular
			? ''
			: 'border-radius: 100vh'}; // this defaults the border radius to half the size of smallest side
	overflow: hidden !important;
	width: ${props => props.$width || '100%'};
	height: ${props => props.$height || '10px'};
`
