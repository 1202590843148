import React, { type AbstractComponent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import fourPlusStationMap from '../../constants/juniorPlusStationMap'
import k3StationMap from '../../constants/stationMap'
import { getIsJuniorPlus, getStation, switchStation } from '../../store/stores/general'
import styled, { css, keyframes } from 'styled-components/macro'
import { isGivenStationInQuestionPhase } from '../StationUpgradeOverlay/selectors'
import {
	getPrioritizedStation,
	getStationActivity,
	PRIORITIZED_4PLUS_LIST,
	PRIORITIZED_K3_LIST,
} from '../../utility/selectors'
import { ACCENT_BLUE } from '../../constants/styles'
import { useAdvanceTraining } from '../../store/selectors/jrPlusState/training'

// Whether the station is active or not (considering being in the question phase as being active)
const isStationActive_selector = (state, station) => {
	return (
		isGivenStationInQuestionPhase(state, station) ||
		(station ? getStationActivity(state)[station] : false)
	)
}

/**
 * Allows a single station to be selected from the footer frame. Pulses if the station that
 * is allowed to be selected is a higher priority than the current frame selected.
 */
export default function StationSelector(props: {|
	width?: string,
	height?: string,
	className?: string,
|}): React$Node {
	const is4Plus = useSelector(getIsJuniorPlus)
	const stationKey = useSelector(getPrioritizedStation)
	const currentStation = useSelector(getStation)
	const isCurrentStationActive = useSelector(state =>
		isStationActive_selector(state, currentStation)
	)
	const advanceTraining = useAdvanceTraining()
	const dispatch = useDispatch()
	const stationMap = is4Plus ? fourPlusStationMap : k3StationMap({})
	const priorityList = is4Plus ? PRIORITIZED_4PLUS_LIST : PRIORITIZED_K3_LIST
	if (!stationKey || !stationMap[stationKey]) {
		return null
	}
	return (
		<StationWrapperSvg
			{...props}
			icon={stationMap[stationKey].icon}
			isHighPriority={
				currentStation !== stationKey &&
				(priorityList.indexOf(stationKey) < priorityList.indexOf(currentStation) ||
					!isCurrentStationActive)
			}
			onClick={() => {
				dispatch(switchStation(stationKey))
				advanceTraining && advanceTraining()
			}}
		/>
	)
}

function StationWrapperSvg({
	icon: Icon,
	isHighPriority,
	onClick,
	className,
	...props
}: {|
	icon: AbstractComponent<*, *>,
	isHighPriority: boolean,
	onClick: () => void,
	className?: string,
	width?: string,
	height?: string,
|}) {
	return (
		<svg
			x="1772"
			y="24.75"
			className={`${className ? className + ' ' : ''} station-select`}
			width="148"
			height="139"
			viewBox="0 0 148 139"
			css="cursor: pointer;"
			fill="none"
			onClick={onClick}
			{...props}>
			<g id="Button_v_2">
				<path
					id="Vector"
					d="M148 139H70.1997C31.8997 139 0.799805 107.9 0.799805 69.5999C0.799805 31.2999 31.8997 0.199951 70.1997 0.199951H148V139Z"
					fill="#5D568C"
				/>
				<g id="Group">
					<path
						id="Vector_2"
						d="M71.2998 129.5C104.437 129.5 131.3 102.637 131.3 69.5C131.3 36.3629 104.437 9.5 71.2998 9.5C38.1627 9.5 11.2998 36.3629 11.2998 69.5C11.2998 102.637 38.1627 129.5 71.2998 129.5Z"
						fill="#0F193C"
					/>
					<InnerCircle
						$isHighPriority={isHighPriority}
						id="station-btn"
						cx="71.3"
						cy="69.5"
						r="42.5"
						fill="url(#paint0_linear_1331_765)"
					/>
				</g>
			</g>
			<Icon x="28.8" y="27" width="85" height="85" />
			<defs>
				<linearGradient
					id="paint0_linear_1331_765"
					x1="71.3329"
					y1="174.706"
					x2="71.3329"
					y2="72.7445"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#1B1464" />
					<stop offset="0.2605" stopColor="#1F1C69" />
					<stop offset="0.6708" stopColor="#2B3377" />
					<stop offset="0.9944" stopColor="#374985" />
				</linearGradient>
			</defs>
		</svg>
	)
}

const MAX_GROW = 1.2
const MIN_GROW = 1

const grow = keyframes`
0% {
	transform: scale(${MIN_GROW});
}
100% {
	transform: scale(${MAX_GROW});
}
`

const InnerCircle = styled.circle`
	transform-origin: 50% 50%;
	${({ $isHighPriority }) =>
		$isHighPriority &&
		css`
			box-shadow: 0 0 10px ${ACCENT_BLUE};
			animation: ${grow} 500ms ease-in-out infinite alternate;
		`};
`
