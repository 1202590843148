// @flow
import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import type { Map as MapType, MapIcon } from '@mission.io/mission-toolkit'
import styled from 'styled-components/macro'
import { useRefResize } from '../../../utility/hooks'
import { MapObject, OptionSelector } from './MapObjects'
import {
	MAP_SIZE,
	type InfoForOtherActions,
	CLIP_PATH_URL,
	CLIP_PATH_ID,
} from './helpers/constants'
import {
	forEachObjectInMap,
	getLockedActions,
	getMapObjectData,
	isCompleted,
} from './helpers/functions'
import { MapContext } from '../mapContext'
import { useAdvanceTraining } from '../../../store/selectors/jrPlusState/training'
import { isTraining as isTraining_selector } from '../../../store/stores/general'
import { Generic } from '../../../images/mapIcons'
import {
	getBackgroundImage,
	getObjectsWithActiveStations,
	getStudentStatus,
} from '../../../store/selectors/jrPlusState/sensor'
import { descriptorSizeMap } from '../types'
import { getCardId, useLineDrawingContext } from '../LineDrawingContext'

import type { MapObjectInfo, StudentStatus } from '../../../store/selectors/jrPlusState/sensor'
import type { SizeKey } from '../types'
import { useContextualData } from '../../../store/selectors/selectorHooks'

const MapContainer = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-right: 8px;
	svg {
		overflow: visible;
	}
`

type Props = {
	missionMap: ?MapType,
	objectInfo: ?MapObjectInfo,
	studentId: string,
}

const OFFSET: number = 0

export default function Map(props: Props): React$Node {
	const [selectedObjectId, setSelectedObjectId] = useState('')
	const [hoveredObjectId, setHoveredObjectId] = useState('')
	const objectsActivatingStations = useSelector(getObjectsWithActiveStations)
	const studentStatus: ?StudentStatus = useSelector(state =>
		getStudentStatus(state, props.studentId)
	)
	const backgroundImage = useSelector(getBackgroundImage)
	const isTraining = useSelector(isTraining_selector)
	const advanceTraining = useAdvanceTraining()
	const mapRef = useRef<?Element>(null)

	const { width: widthInScreenCoordinates, height: heightInScreenCoordinates } = useRefResize(
		mapRef
	)
	const { resetConnectingIds, connectingIds } = useLineDrawingContext()
	const map = props.missionMap
	const contextualData = useContextualData({ type: 'MAP', map })

	const getCardIdFromMapObjectId = (objectId: string): string | void => {
		const contextualDataForObject = contextualData.find(
			({ dataSource }) => dataSource.type === 'MAP_OBJECT' && dataSource.mapObjectId === objectId
		)

		return contextualDataForObject && getCardId(contextualDataForObject.id)
	}

	const onClickItem = (e: SyntheticMouseEvent<>, id: string, drawLines) => {
		e.stopPropagation()
		const cardId = getCardIdFromMapObjectId(id)
		if (cardId && cardId !== connectingIds.startElementId && drawLines) {
			resetConnectingIds?.({ drawLines: true, cardId, mapObjectId: id })
		} else {
			resetConnectingIds?.({ drawLines: false })
		}

		setSelectedObjectId(id)
		if (advanceTraining) advanceTraining({ objectId: id })
	}

	const setHover = (isHovering: boolean, objectId: string) => {
		setHoveredObjectId(isHovering ? objectId : '')
	}

	const viewBox = '0 0 ' + MAP_SIZE + ' ' + MAP_SIZE

	const selectorOptions: React$Node[] = []
	const mapObjects: React$Node[] = []
	let selectorDetails: React$Node = null
	if (map) {
		forEachObjectInMap(map, (mapObject, key) => {
			const object = props.objectInfo ? props.objectInfo[mapObject._id] : null
			let sizeData: ?SizeKey | { width: number, height: number }
			let shouldCenterCoordinates: boolean = true

			if (object && object.isHidden) {
				return
			}
			const mapObjectType = 'OTHER'
			let component = Generic
			if (mapObject.icon) {
				const icon = mapObject.icon
				component = getSVGIcon(icon)
				sizeData = { width: icon.width, height: icon.height }
			}
			const scanComplete = object && isCompleted(object, 'SCAN')

			const { sizes, viewX, viewY, centeredX, centeredY } = getMapObjectData(
				mapObjectType,
				mapObject,
				sizeData,
				shouldCenterCoordinates
			)

			const actionsActivatingStations = objectsActivatingStations[mapObject._id] ?? []
			const lockedActions = studentStatus
				? getLockedActions(mapObject._id, studentStatus, objectsActivatingStations)
				: []

			const infoForOtherActions: InfoForOtherActions[] = []
			if (object) {
				for (let key in object.actionData) {
					if (object.actionData.hasOwnProperty(key)) {
						const currentAction = object.actionData[key]
						if (key !== 'SCAN' && currentAction.allowed) {
							infoForOtherActions.push({
								// $FlowFixMe[incompatible-call] all keys to action data are string literal action types.
								key,
								locked: lockedActions.includes(key),
								isStationActive: actionsActivatingStations.includes(key),
								complete: currentAction.complete,
								students: currentAction.students,
							})
						}
					}
				}
			}
			const showActionModal = !scanComplete || infoForOtherActions.length > 0
			if (object && showActionModal) {
				const selected = selectedObjectId === mapObject._id
				selectorOptions.push(
					<OptionSelector
						infoForOtherActions={infoForOtherActions}
						scanComplete={Boolean(scanComplete)}
						name={mapObject.name}
						subtitle={mapObject.subtitle}
						key={mapObject._id}
						objectId={mapObject._id}
						objectType={mapObjectType}
						sizes={sizes}
						objectInfo={object}
						x={viewX}
						y={viewY}
						open={selected}
						amIScanning={studentStatus?.scanning === mapObject._id}
						hovered={hoveredObjectId === mapObject._id}
					/>
				)
			}
			mapObjects.push(
				<MapObject
					key={mapObject._id}
					amIScanning={studentStatus?.scanning === mapObject._id}
					component={component}
					mapObjectId={mapObject._id}
					onClick={(e, id) => onClickItem(e, id, !showActionModal)}
					objectInfo={object}
					sizes={sizes}
					centeredX={centeredX}
					centeredY={centeredY}
					name={mapObject.name}
					subtitle={mapObject.subtitle}
					setHover={setHover}
					selected={selectedObjectId === mapObject._id}
					infoForOtherActions={infoForOtherActions}
				/>
			)
		})
	}
	const radius = (MAP_SIZE - OFFSET) / 2
	return (
		<MapContainer>
			<MapContext.Provider
				value={{
					widthSvg: MAP_SIZE,
					heightSvg: MAP_SIZE,
					widthInScreenCoordinates: Math.min(widthInScreenCoordinates, heightInScreenCoordinates),
					heightInScreenCoordinates: Math.min(widthInScreenCoordinates, heightInScreenCoordinates),
				}}>
				<svg
					onClick={() => {
						!isTraining && setSelectedObjectId(null)
						resetConnectingIds?.({ drawLines: false })
					}}
					viewBox={viewBox}
					preserveAspectRatio="xMidYMid meet"
					width="100%"
					height="100%"
					overflow="visible"
					ref={mapRef}>
					<defs>
						<circle id="circle" cx={MAP_SIZE / 2} cy={MAP_SIZE / 2} r={radius} />
					</defs>
					<clipPath id={CLIP_PATH_ID}>
						<use xlinkHref="#circle" />
					</clipPath>
					<RenderedMap />
					{backgroundImage && (
						<image
							clipPath={CLIP_PATH_URL}
							href={backgroundImage}
							xlinkHref={backgroundImage}
							height={`${radius * 2}px`}
							width={`${radius * 2}px`}
							x="0"
							y="0"
						/>
					)}
					{mapObjects}
					{selectorOptions}
					{/* For now, we shouldn't show the selectorDetails model in training mode, because it gets in the way */}
					{!isTraining && selectorDetails}
				</svg>
			</MapContext.Provider>
		</MapContainer>
	)
}

function RenderedMap() {
	const center = MAP_SIZE / 2
	const radius = (MAP_SIZE - OFFSET) / 2
	return <circle cx={center} cy={center} r={radius} css="fill: var(--color-on-base)" />
}

export const getSVGIcon = (icon: MapIcon): React$ComponentType<any> => {
	const svgIcon = (props: {}): React$Node => (
		<SvgIcon {...props} url={icon.url} width={icon.width} height={icon.height} />
	)
	return svgIcon
}

/**
 * Renders an svg icon with the given url, width, and height
 */
export const SvgIcon = ({
	url,
	width,
	height,
	...props
}: {
	url: string,
	width: number,
	height: number,
}): React$Node => {
	return (
		<svg {...props}>
			<image
				xlinkHref={url}
				height={height || descriptorSizeMap.OTHER}
				width={width || descriptorSizeMap.OTHER}
			/>
		</svg>
	)
}
