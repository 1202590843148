import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import useSpeechSynthesis from '../../utility/useSpeechSynthesis'
import { FaVolumeOff, FaVolumeUp } from 'react-icons/fa'
import { GENDER, VOICE_NAMES, VOICE_LANG } from '../../constants/speechConstants'
import { filter, head } from 'lodash'
import { ACCENT_BLUE } from '../../constants/styles'
import { getWordsAt } from '../../utility/functions'

import { getTextToSpeechSettings } from '../../store/stores/settings'

const rate = 1
const pitch = 1

const TextToSpeechButton = styled.button`
	${({ $position }) =>
		$position === 'start'
			? `margin-right: 0.5rem;`
			: $position === 'end'
			? `margin-left: 0.5rem;`
			: ''}
	vertical-align: middle;
	pointer-events: auto;
`

const TextToSpeechLabel = styled.span`
	color: ${ACCENT_BLUE};
`

/**
 * A function to get the string text of the children component
 * @param {Array<React$Node>} children child component of TextToSpeech
 * @returns {string} child, string text of the children component
 */
const getChildrenText = (children: Array<React$Node>): string =>
	React.Children.map(children, child => {
		if (typeof child !== 'string') {
			return getChildrenText(child?.props?.children)
		}
		return child
	})?.join(' ')

/**
 * A HOC react component to use SpeechSynthesis API
 * It has a string children component used for the SpeechSynthesis API
 * @param {{
 *		children: any, children component wrapped for the TextToSpeech,
 *		onStartSpeech: Function, Callback fired when the browser read some text,
 *  }} props
 * @returns {TextToSpeech} React component
 */
export default function TextToSpeech(props: {
	children: any,
	position?: 'start' | 'end',
	onStartSpeech?: (event: TouchEvent | MouseEvent) => void,
	onEndSpeech?: () => void,
}): React$Node {
	let lastWord = ''
	const onBoundary = event => {
		const words = getWordsAt(text, event?.charIndex)
		const newWord = words?.word
		if (newWord !== lastWord) {
			setBeforeWord(words?.before)
			setWord(words?.word)
			setAfterWord(words?.after)
			lastWord = newWord
		}
	}

	const onEnd = () => {
		setBeforeWord(text)
		setWord('')
		setAfterWord('')
		lastWord = ''
		if (props.onEndSpeech) props.onEndSpeech()
	}

	const { speak, voices, cancel, speaking } = useSpeechSynthesis({
		onBoundary,
		onEnd,
	})
	const [word, setWord] = useState('')
	const [afterWord, setAfterWord] = useState('')
	const settings = useSelector(getTextToSpeechSettings)
	const voiceNames = settings.gender === GENDER.FEMALE ? VOICE_NAMES.WOMAN : VOICE_NAMES.MAN
	const text = getChildrenText(props.children)
	const [beforeWord, setBeforeWord] = useState(text)

	const voice = head(
		filter(
			voices,
			voice => voice.lang === VOICE_LANG && voiceNames.some(name => voice.name.includes(name))
		)
	)

	const startSpeech = (event: TouchEvent | MouseEvent) => {
		speak({ text, voice, rate, pitch })
		if (props.onStartSpeech) props.onStartSpeech(event)
	}
	const position = props.position ?? 'end'

	const startEndSpeechIcon =
		settings.isEnabled &&
		text.length > 0 &&
		(speaking ? (
			<TextToSpeechButton $position={position} onClick={() => cancel()}>
				<FaVolumeUp />
			</TextToSpeechButton>
		) : (
			<TextToSpeechButton $position={position} onClick={e => startSpeech(e)}>
				<FaVolumeOff />
			</TextToSpeechButton>
		))

	return (
		<span>
			{position === 'start' && startEndSpeechIcon}
			{speaking ? (
				<span>
					<span>{beforeWord}</span>
					<TextToSpeechLabel>{word}</TextToSpeechLabel>
					<span>{afterWord}</span>
				</span>
			) : (
				<span>{props.children}</span>
			)}
			{position === 'end' && startEndSpeechIcon}
		</span>
	)
}
