import React from 'react'
import { ShipOverview } from './components'
import styled from 'styled-components/macro'
import Panel from '../../components/Frame/JrPlusFrame/components/Toolkit/Panel'

export default function Decks(): React$Node {
	return (
		<DecksStation className="p-6">
			<div className="p-5 w-full h-full flex gap-5">
				<div className="z-[1]">
					<Panel />
				</div>
				<ShipOverview />
			</div>
		</DecksStation>
	)
}

const DecksStation = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: flex-end;
	height: 100%;
`
