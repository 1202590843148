import React from 'react'
import styled from 'styled-components/macro'
import Switch from 'react-switch'
import { ACCENT_BLUE, LIGHT_GREEN } from '../../../constants/styles'

const StyledSwitchComponent = styled.div`
	display: flex;
	align-items: center;
	color: white;
	height: min-content;
`

const StyledSwitchLabel = styled.label`
	padding-left: 8px;
`

const StyledSwitchIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`
/**
 * A basic toggle component as a wrapper of react-switch library
 * It has a customize styles and a label placeholder
 * @param {{
 *		checked: boolean, if true, the toggle is set to checked. If false, it is not checked,
 *		onChange: Function, callback fired when the user drag the switch,
 *	  placeholder: string, text to describe the toggle functionality,
 *	  type: 'circle' | 'square', define the style of the switch component,
 *	  leftIcon: icon, react icon for the left site of the square toggle,
 *	  rightIcon: icon, react icon for the right site of the square toggle,
 *  }} props
 * @returns {component} StyledSwitchComponent - the toggle component
 */
export default function Toggle({
	checked,
	onChange,
	placeholder,
	type = 'circle',
	leftIcon = <></>,
	rightIcon = <></>,
}: {
	checked: boolean,
	onChange: (checked: boolean, event: TouchEvent | MouseEvent, id: string) => void,
	placeholder?: string,
	type?: string,
	leftIcon?: any,
	rightIcon?: any,
}): React$Node {
	return (
		<StyledSwitchComponent>
			{type === 'circle' && (
				<Switch onColor={ACCENT_BLUE} onChange={onChange} width={65} checked={checked} />
			)}
			{type === 'square' && (
				<Switch
					checked={checked}
					onChange={onChange}
					width={65}
					borderRadius={6}
					offColor={ACCENT_BLUE}
					onColor={ACCENT_BLUE}
					offHandleColor={LIGHT_GREEN}
					onHandleColor={LIGHT_GREEN}
					uncheckedIcon={<StyledSwitchIcon>{rightIcon}</StyledSwitchIcon>}
					checkedIcon={<StyledSwitchIcon>{leftIcon}</StyledSwitchIcon>}
					uncheckedHandleIcon={<StyledSwitchIcon>{leftIcon}</StyledSwitchIcon>}
					checkedHandleIcon={<StyledSwitchIcon>{rightIcon}</StyledSwitchIcon>}
				/>
			)}
			<StyledSwitchLabel>{placeholder}</StyledSwitchLabel>
		</StyledSwitchComponent>
	)
}
