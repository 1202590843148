import React from 'react'
import Slider from '../Components/Slider'
import Toggle from '../Components/Toggle'
import { PrimaryLayer, SecondaryLayer } from '../SharedColors'
import { COMPONENT_TYPES } from '../../../../../../store/selectors/jrPlusState/engineering'
import type {
	SliderData as SliderComponent,
	ToggleData as ToggleComponent,
} from '@mission.io/mission-toolkit'
import type { PopulatedPanel } from '../../../../../../store/selectors/jrPlusState/engineering'
import styled from 'styled-components/macro'

const COMPONENT_MAP = {
	[COMPONENT_TYPES.SLIDER]: Slider,
	[COMPONENT_TYPES.TOGGLE]: Toggle,
}

const StyledSwitchPanel = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: 8px;
`

const StyledSliders = styled.div`
	display: flex;
	justify-content: space-around;
`

const StyledButtons = styled.div`
	display: flex;
	height: 100%;
	justify-content: space-around;
	flex-direction: column;
`

export default function SwitchPanel({ panel }: { panel: PopulatedPanel }): React$Node {
	let components: { [componentType: string]: (SliderComponent | ToggleComponent)[] } = {}
	panel.components.forEach(component => {
		if (component.type !== COMPONENT_TYPES.SLIDER && component.type !== COMPONENT_TYPES.TOGGLE) {
			return
		}
		if (!components[component.type]) {
			components[component.type] = []
		}
		components[component.type].push(component)
	})
	Object.keys(components).forEach(key =>
		components[key].sort((a, b) => a.name.localeCompare(b.name))
	)
	let renderedComponents = {}
	Object.keys(components).forEach(type => {
		renderedComponents[type] = []
		components[type].forEach(component => {
			const Component = COMPONENT_MAP[type]
			renderedComponents[type].push(<Component component={component} key={component.id} />)
		})
	})
	return (
		<StyledSwitchPanel>
			<SecondaryLayer css="grid-column: 1;">
				<StyledButtons>{renderedComponents[COMPONENT_TYPES.TOGGLE]}</StyledButtons>
			</SecondaryLayer>
			<PrimaryLayer css="grid-column: 2;">
				<StyledSliders>{renderedComponents[COMPONENT_TYPES.SLIDER]}</StyledSliders>
			</PrimaryLayer>
		</StyledSwitchPanel>
	)
}
