import React from 'react'
import styled from 'styled-components'
import { animated, useSpring } from '@react-spring/web'
import { ACCENT_BLUE, DARKEST_SHADE } from '../../../../../../constants/styles'

type Props = {
	value: number,
	maxValue: number,
	style: {},
}

const PointSVGBox = styled.svg`
	margin: auto 0;
	width: 100%;
	height: 100%;
`
const PointBackground = styled.rect`
	fill: ${DARKEST_SHADE};
	width: 100%;
	height: 100%;
`
const PointFill = styled(animated.rect)`
	fill: ${ACCENT_BLUE};
	height: 100%;
`

export default function Progress({ value, maxValue, style }: Props): React$Node {
	const pointFillAnimation = useSpring({ width: `${(value / maxValue) * 100}%` })

	return (
		<PointSVGBox style={style}>
			<PointBackground />
			<PointFill style={pointFillAnimation} />
		</PointSVGBox>
	)
}
