// @flow
import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { EMPTY_SPACE } from '../../../../../constants/styles'
import { OBJECT_ACTION_STYLES } from './definitions'
import { getStationActivity } from '../../../../../utility/selectors'
import { Check, Hourglass } from '../../../../../images/mapIcons'
import { getStudentId, switchStation } from '../../../../../store/stores/general'
import { getTotalRequiredStudentsForAction } from '../../../../../store/selectors/jrPlusState/sensor'
import { ACTIONS_TO_STATIONS, type InfoForOtherActions } from '../../helpers/constants'

/**
 * Displays a button for a student to select in order to take action on an object
 * @returns {React$node}
 */
export default function ActionButton(props: {
	info: InfoForOtherActions,
	onClick: (e: SyntheticMouseEvent<HTMLButtonElement>, boolean) => void,
}): React$Node {
	const dispatch = useDispatch()
	const { key, isStationActive, complete, students, locked } = props.info
	const stationId = ACTIONS_TO_STATIONS[key]
	const studentId = useSelector(state => getStudentId(state.general))
	const totalEngagedStudents = Object.keys(students).filter(
		studentId => students[studentId] === true
	).length
	const stationIsGloballyActive = useSelector(state => getStationActivity(state)[stationId])
	const stationInUse = !isStationActive && stationIsGloballyActive
	const triggerStationChange = () => dispatch(switchStation(stationId))
	const studentsNeeded = useSelector(state => getTotalRequiredStudentsForAction(state, key))
	const amIActive = studentId && students[studentId]

	const percent = ((totalEngagedStudents / studentsNeeded) * 100).toFixed()
	const { icon: Icon } = OBJECT_ACTION_STYLES[key]
	return (
		<StyledButton
			$actionType={key}
			$locked={locked || stationInUse}
			$activated={amIActive && !isStationActive}
			disabled={locked || amIActive || complete}
			onClick={e => {
				e.stopPropagation()
				if (!stationInUse) {
					if (isStationActive) {
						triggerStationChange()
					} else if (!complete) {
						props.onClick(e, Boolean(amIActive))
					}
				}
			}}>
			{stationInUse && !complete ? (
				<Hourglass width="8px" height="8px" />
			) : isStationActive ? (
				<span style={{ color: EMPTY_SPACE }}>GO</span>
			) : totalEngagedStudents > 0 ? (
				`${percent}%`
			) : complete ? (
				<Check color="white" />
			) : (
				<Icon className="clickable" width="8px" height="8px" />
			)}
		</StyledButton>
	)
}
const StyledButton = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	color: ${EMPTY_SPACE};
	justify-content: center;
	&:not(:last-child) {
		margin-right: 2px;
	}
	cursor: pointer;
	background-color: ${({ $actionType, $activated, $locked }) =>
		$activated
			? OBJECT_ACTION_STYLES[$actionType].activeColor
			: $locked
			? OBJECT_ACTION_STYLES[$actionType].disabledColor
			: OBJECT_ACTION_STYLES[$actionType].color};
	&:hover {
		transition: fill 0.1s ease-in-out;
		background-color: ${({ $actionType, $activated, $locked }) =>
			$activated
				? OBJECT_ACTION_STYLES[$actionType].activeColor
				: $locked
				? OBJECT_ACTION_STYLES[$actionType].disabledColor
				: OBJECT_ACTION_STYLES[$actionType].hoverColor};
	}
`
