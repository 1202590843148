import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css, keyframes } from 'styled-components'
import { animated } from '@react-spring/web'
import {
	getCurrentRoomIdOfRobot,
	getRobotAction,
	getStudentDeckData,
} from '../../../store/selectors/jrPlusState/decks'
import { isTraining } from '../../../store/stores/general'
import RobotImage from '../../../images/robot2Small.png'

import type { Coordinates as Point } from '../../../types'
import { isRepairsActive } from '../../../store/selectors/jrPlusState/decks'

const ROBOT_SIZE = 44
const ROBOT_HEIGHT = 59.348
const ROBOT_HOVER_SIZE = 54
const ROBOT_ROTATE_RADIUS = 12

export default function Robot({
	location,
	selectRobot,
	isMoving,
	selected,
}: {
	location: Point,
	selectRobot: () => void,
	isMoving: boolean,
	selected: boolean,
}): React$Node {
	const isStationActive = useSelector(isRepairsActive)
	const robotAction = useSelector(getRobotAction)
	const isRobotFixingAlert = useSelector(state => {
		const robotRoom = getCurrentRoomIdOfRobot(state)
		const alerts = getStudentDeckData(state)?.alerts
		const robotAlert = alerts?.find(alert => alert.roomId === robotRoom)
		if (robotAlert?.fixingTimerId || robotAlert?.waitingOn) {
			return true
		}
		return false
	})
	const isTrainingMode = useSelector(isTraining)
	const selectable =
		// Lock robot in the room until it is done fixing alert. If this changes then it also needs to be changed in analytics.
		// We measure a student's initiative based on when they move a robot after the robot becomes available. The robot becomes available when it is done fixing an alert
		isStationActive && !robotAction && !isRobotFixingAlert && !isMoving && !selected
	return (
		<RobotStyle
			className="decksRobot"
			istrainingmode={isTrainingMode.toString()}
			$selectable={String(selectable)}
			action={robotAction}
			xlinkHref={RobotImage}
			x={location.x}
			y={location.y}
			width={ROBOT_SIZE}
			height={ROBOT_HEIGHT}
			onClick={() => {
				if (selectable) {
					selectRobot()
				}
			}}
		/>
	)
}

const RobotStyle = styled(animated.image)`
	width: ${ROBOT_SIZE}px;
	height: ${ROBOT_HEIGHT}px;
	transform: translate(-${ROBOT_SIZE / 2}px, -${ROBOT_SIZE / 2}px);
	cursor: pointer;
	position: fixed;
	${({ $selectable, istrainingmode }) => {
		return $selectable === 'true'
			? istrainingmode === 'false' &&
					css`
						&:hover {
							width: ${ROBOT_HOVER_SIZE}px;
							transform: translate(-${ROBOT_HOVER_SIZE / 2}px, -${ROBOT_HOVER_SIZE / 2}px);
							animation: ${BouncingHoveredRobot} 1s infinite;
						}
						animation: ${BouncingRobot} 1s infinite;
					`
			: `pointer-events: none; `
	}}
	${({ action }) =>
		action &&
		css`
			animation: ${ActionRobot} 3s infinite linear;
		`};
`

const BouncingRobot = keyframes`
    0%, 20%, 50%, 80%, 100% {transform: translate(-${ROBOT_SIZE / 2}px, -${ROBOT_SIZE /
	2}px) translateY(0);}
    40% {transform: translate(-${ROBOT_SIZE / 2}px, -${ROBOT_SIZE / 2}px) translateY(-25px);}
	60% {transform: translate(-${ROBOT_SIZE / 2}px, -${ROBOT_SIZE / 2}px) translateY(-25px);}
`

const BouncingHoveredRobot = keyframes`
    0%, 20%, 50%, 80%, 100% {transform: translate(-${ROBOT_HOVER_SIZE / 2}px, -${ROBOT_HOVER_SIZE /
	2}px) translateY(0);}
    40% {transform: translate(-${ROBOT_HOVER_SIZE / 2}px, -${ROBOT_HOVER_SIZE /
	2}px) translateY(-25px);}
	60% {transform: translate(-${ROBOT_HOVER_SIZE / 2}px, -${ROBOT_HOVER_SIZE /
	2}px) translateY(-25px);}
`

const ActionRobot = keyframes`
	from {
		transform: translate(-${ROBOT_SIZE / 2}px, -${ROBOT_SIZE /
	2}px) rotate(0deg) translate(-${ROBOT_ROTATE_RADIUS}px) rotate(0deg);
	}	
	to {
		transform: translate(-${ROBOT_SIZE / 2}px, -${ROBOT_SIZE /
	2}px) rotate(360deg) translate(-${ROBOT_ROTATE_RADIUS}px) rotate(-360deg);
	}
`
