import styled from 'styled-components'
import { EMPTY_SPACE } from '../../constants/styles'
import type { StyledType } from '../../types/utilityTypes'

const BACKGROUND_COLOR = EMPTY_SPACE
const TEXT_COLOR = EMPTY_SPACE

const getFilledStyle = ({ iconOnly }: { iconOnly?: boolean }) => `
	${iconOnly ? `fill: ${TEXT_COLOR};` : ''};
	pointer-events: none;
`

type FilledStyledType<elementName> = StyledType<
	{ iconOnly?: boolean },
	React$ElementRef<elementName>
>

export const FilledPath: FilledStyledType<'path'> = styled.path`
	${getFilledStyle}
`
export const FilledCircle: FilledStyledType<'circle'> = styled.circle`
	${getFilledStyle}
`
export const FilledEllipse: FilledStyledType<'ellipse'> = styled.ellipse`
	${({ iconOnly }) => (iconOnly ? `stroke: ${TEXT_COLOR}` : '')};
	pointer-events: none;
`
export const FilledPolygon: FilledStyledType<'polygon'> = styled.polygon`
	${getFilledStyle}
`
export const BackgroundCircle: StyledType<{}, React$ElementRef<'circle'>> = styled.circle`
	fill: ${BACKGROUND_COLOR};
`

export type Props = {
	iconOnly?: boolean,
	isOpen?: boolean,
	transitionPattern?: { [key: string]: string },
}
