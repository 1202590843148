import React from 'react'
import { useState } from 'react'
import type { ObjectType } from '@mission.io/mission-toolkit'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import {
	MediaEventInput,
	TextOption,
	TextEventInput,
	SpeakingPromptOption,
	ContentExpander,
} from './helperComponents'
import { LiteracyEventFrame, IntroFrame, PendingSubmissionFrame, DisplayGrade } from './displays'
import {
	useExplanationForStudentChoice,
	useLiteracyEvent,
	useLiteracyEventDisplayStatus,
} from './hooks'
import { Button } from '../basics'
import { DISPLAY_STATUS } from './hooks/useLiteracyEventDisplayStatus'
import { useDispatch } from 'react-redux'
import { sendMessage } from '../../store/stores/webSocket'
import ExplanationFrame from './displays/LiteracyEventFrame/ExplanationFrame'
import { VideoController } from '../VideoController'
import { LiteracyEventFeedback } from './Feedback'
import { LITERACY_EVENT_POINT_PING_LOCATION } from './displays/LiteracyEventFrame/constants'
import Loading from '../basics/Loading.jsx'
import { CloseReadingPrompt } from './helperComponents/CloseReadingPrompt'

/**
 * LiteracyEvent - A component that renders the literacy events reading contexts, tasks, and feedback
 *
 * @return {React$Node}
 */
export function LiteracyEvent(): React$Node {
	return (
		<>
			<LiteracyEventReadingContext />
			<LiteracyEventFeedback />
		</>
	)
}

/**
 * A component that manages all states of the literacy event and inserts the proper jsx into the LiteracyEventFrame component when it is time to display the event.
 * @returns {React$Node}
 */
function LiteracyEventReadingContext(): React$Node {
	const dispatch = useDispatch()
	const literacyEvent = useLiteracyEvent()
	const currentTask = literacyEvent?.taskData
	/* Use the previous rendered task data to fix bug where the literacy event frame would update to soon when exiting a speaking task.
	 This still works during the literacy event initialization because the task data is not rendered initially -- an INTRO screen is rendered -- allowing
	 `mostRecentTask` to be set before we need to display it to the screen.*/
	const [status, setStatus] = useLiteracyEventDisplayStatus(literacyEvent?.id)
	const studentChoiceExplanation = useExplanationForStudentChoice()
	const [selectedIndex, setSelectedIndex] = React.useState()

	// Resets the selected option when the task changes
	React.useEffect(() => {
		setSelectedIndex(null)
	}, [currentTask?.instanceId])

	const onFinishExit = React.useCallback(() => {
		if (currentTask && currentTask?.type === LITERACY_EVENT.TASK.TYPE.SPEAKING) {
			dispatch(
				sendMessage('LITERACY_EVENT_DISMISS_SPEAKING_TASK', {
					taskInstanceId: currentTask.instanceId,
				})
			)
		}
		setStatus(DISPLAY_STATUS.SUBMITTING)
	}, [setStatus, currentTask, dispatch])

	const onSubmit = React.useCallback(() => {
		setStatus(DISPLAY_STATUS.EXITING, {
			for: currentTask ? { id: currentTask.instanceId, type: currentTask.type } : undefined,
		})
	}, [setStatus, currentTask])

	const onFinishIntro = React.useCallback(() => {
		setStatus(DISPLAY_STATUS.ACTIVE)
	}, [setStatus])

	const onFinishSubmissionLoading = React.useCallback(() => {
		setStatus(DISPLAY_STATUS.DISPLAY_GRADE)
	}, [setStatus])

	const onFinishDisplayGrade = React.useCallback(() => {
		if (studentChoiceExplanation) {
			setStatus(DISPLAY_STATUS.SHOW_EXPLANATION)
		} else {
			const taskData = literacyEvent?.taskData
			if (!taskData) return
			let dismissalMessageType
			if (taskData.type === LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE) {
				dismissalMessageType = 'LITERACY_EVENT_DISMISS_STUDENT_MULTIPLE_CHOICE_FEEDBACK'
			} else if (taskData.type === LITERACY_EVENT.TASK.TYPE.CLOSE_READING) {
				dismissalMessageType = 'LITERACY_EVENT_DISMISS_CLOSE_READING_FEEDBACK'
			}
			if (!dismissalMessageType) return
			dispatch(
				// This message doesn't just dismiss the multiple choice feedback, it also dismisses the task, and literacy event for the student if necessary.
				sendMessage(
					dismissalMessageType,
					{
						taskInstanceId: taskData.instanceId,
					},
					LITERACY_EVENT_POINT_PING_LOCATION
				)
			)
		}
	}, [setStatus, studentChoiceExplanation, literacyEvent, dispatch])

	if (!literacyEvent) {
		return null
	}

	if (status === DISPLAY_STATUS.INTRO) {
		return <IntroFrame onEnd={onFinishIntro} />
	}
	if (status === DISPLAY_STATUS.SUBMITTING) {
		return (
			<PendingSubmissionFrame
				onEnd={onFinishSubmissionLoading}
				text={
					literacyEvent?.taskData?.type === LITERACY_EVENT.TASK.TYPE.CLOSE_READING
						? 'Submitting Work'
						: literacyEvent?.taskData?.type === LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE
						? 'Submitting Decision'
						: 'Submitting Response'
				}
			/>
		)
	}
	if (status === DISPLAY_STATUS.DISPLAY_GRADE) {
		return <DisplayGrade onEnd={onFinishDisplayGrade} />
	}
	if (status === DISPLAY_STATUS.SHOW_EXPLANATION && studentChoiceExplanation) {
		return <ExplanationFrame text={studentChoiceExplanation} />
	}
	if (status === DISPLAY_STATUS.ACTIVE || status === DISPLAY_STATUS.EXITING) {
		const mediaExtender =
			literacyEvent.readingContext.text.length > 0 &&
			literacyEvent.readingContext.media.length === 1
				? literacyEvent.readingContext.media[0]
				: null

		return (
			<LiteracyEventFrame
				orientation={getOrientation()}
				informationJsx={<EventInput readingContext={literacyEvent.readingContext} />}
				isExiting={status === DISPLAY_STATUS.EXITING}
				onFinishExit={onFinishExit}
				headline="Incoming Information"
				optionsTitle={getPromptText(currentTask)}
				optionsJsx={getEventTaskOptions({
					readingContextInstanceId: literacyEvent.readingContext.instanceId,
					currentTask,
					onSubmit,
					selectedIndex,
					dispatch,
				})}
				onClickOption={setSelectedIndex}
				extenderJsx={mediaExtender ? <ExtenderJsx media={mediaExtender} /> : null} // If we do not want to display the extender for this literacy event, we can pass in null here.
			/>
		)
	}
	return null
}

/**
 * For now, we only support horizontal literacy event orientation.
 * @returns {'HORIZONTAL'}
 */
function getOrientation() {
	return 'HORIZONTAL'
}

/**
 * Get custom prompt text for the literacy event. For different literacy event variants, this will change.
 * @param {SelectorLiteracyEventCurrentTaskData} currentTask data for the current task of the literacy event
 * @returns
 */
function getPromptText(currentTask) {
	if (!currentTask) {
		return `Review`
	} else if (currentTask.type === LITERACY_EVENT.TASK.TYPE.SPEAKING) {
		return 'Discuss'
	} else if (currentTask.type === LITERACY_EVENT.TASK.TYPE.CLOSE_READING) {
		return 'Look closely'
	} else {
		return currentTask.task.prompt
	}
}

/**
 * Returns an array of jxs for each task option. For different literacy event variants, this will change. These task options will also handle submitting the user's response.
 * @param {Function} onSubmit callback when an option is submitted
 * @returns {Array<TextOption>}
 */
function getEventTaskOptions({
	currentTask,
	onSubmit,
	selectedIndex,
	dispatch,
	readingContextInstanceId,
}) {
	if (!currentTask) {
		return [
			<Button
				className="tracking-normal p-2 px-3 m-1 mx-auto flex justify-center"
				onClick={e => {
					e.stopPropagation()
					dispatch(
						sendMessage('LITERACY_EVENT_REMOVE_EMPTY_READING_CONTEXT', {
							readingContextInstanceId,
						})
					)
					onSubmit()
				}}>
				Done
			</Button>,
		]
	} else if (currentTask.type === LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE) {
		return currentTask.task.options.map((option, index) => (
			<TextOption
				message={currentTask.type}
				onSubmit={() => {
					dispatch(
						sendMessage('LITERACY_EVENT_SUBMIT_STUDENT_MULTIPLE_CHOICE_ANSWER', {
							taskInstanceId: currentTask.instanceId,
							selectedAnswer: option.id,
						})
					)
					onSubmit()
				}}
				index={index}
				selected={selectedIndex === index}
				submitButtonText="Submit"
				taskText={option.text}
			/>
		))
	} else if (currentTask.type === LITERACY_EVENT.TASK.TYPE.CLOSE_READING) {
		return [
			<CloseReadingPrompt
				onSubmit={() => {
					dispatch(
						sendMessage('LITERACY_EVENT_SUBMIT_CLOSE_READING_ANNOTATIONS', {
							taskInstanceId: currentTask.instanceId,
						})
					)
					onSubmit()
				}}
				taskText={currentTask.task.prompt}
			/>,
		]
	} else if (currentTask.type === LITERACY_EVENT.TASK.TYPE.SPEAKING) {
		return [
			<SpeakingPromptOption
				onSubmit={() => {
					dispatch(
						sendMessage(
							'LITERACY_EVENT_COMPLETE_SPEAKING_TASK',
							{
								taskInstanceId: currentTask.instanceId,
							},
							LITERACY_EVENT_POINT_PING_LOCATION
						)
					)
					onSubmit()
				}}
				submitButtonText={currentTask.nextTaskId ? 'Next' : 'Done'}
				task={currentTask}
			/>,
		]
	}
	throw Error('Unsupported literacy event task type: ' + currentTask.type)
}

/**
 * Determines what type of information is being conveyed in the literacy event.
 * @param {FullStateReadingContext} readingContext
 * @returns {ObjectType} 'IMAGE' | 'VIDEO' | 'AUDIO' | 'TEXT'
 */
const getInformationType = (readingContext): ObjectType => {
	if (readingContext.text.length > 0) return 'TEXT'
	else if (readingContext.media.length === 1) {
		const media = readingContext.media[0]
		return media.type
	} else {
		throw Error('Literacy event has no text or media')
	}
}

/**
 * Returns the jsx of the literacy event input that we will display to the user. For different literacy event variants, this should change.
 * @returns {React$node}
 */
function EventInput({ readingContext }) {
	const informationType = getInformationType(readingContext)
	if (informationType === 'TEXT')
		return <TextEventInput text={readingContext.text} title={readingContext.title} />
	else if (readingContext.media.length === 1) {
		const media = readingContext.media[0]
		return <MediaEventInput media={media} title={readingContext.title} />
	}
}
/**
 * The content for a smaller frame frame that will roll out from the side of the literacy event frame. For most text literacy events, this will be an image.
 * @returns {React$Node}
 */
function ExtenderJsx({ media }) {
	const [loaded, setLoaded] = useState(false)

	const mediaJsx =
		media.type === 'IMAGE' ? (
			<img
				alt="Event Alert"
				className="overflow-hidden border-2 border-[--color-accent-aqua]"
				src={media.url}
				onLoad={() => setLoaded(true)}
			/>
		) : media.type === 'VIDEO' ? (
			<div
				className={
					'max-h-full max-w-full min-h-36 aspect-video bg-black rounded-r-xl overflow-hidden'
				}>
				<VideoController autoPlay={false} src={media.url} onLoad={() => setLoaded(true)} />
			</div>
		) : (
			<audio onLoadedData={() => setLoaded(true)} className="ml-2 min-w-36" controls>
				<source src={media.url} />
			</audio>
		)
	return (
		<>
			<div className="[&>img]:max-h-[35vh] [&>img]:rounded-r-xl [&>img]:border-l-0 relative rounded-r-xl">
				{!loaded && (
					<div className="absolute top-0 h-full w-full">
						<Loading className="size-8 inset-center" />
					</div>
				)}
				{mediaJsx}
			</div>
			{media.type !== 'AUDIO' && loaded && (
				<ContentExpander className="bg-[#020223] bottom-8 right-8">{mediaJsx}</ContentExpander>
			)}
		</>
	)
}
