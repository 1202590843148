// @flow
import React, { memo, type AbstractComponent } from 'react'
import styled from 'styled-components'
import { SmileyFace, MehFace, FrownyFace } from '../../../images/surveyIcons'
import type { PowerGaugeStatus } from '../types'

const iconMap = {
	UNDER_TARGET: MehFace,
	ON_TARGET: SmileyFace,
	OVER_TARGET: FrownyFace,
}

type Props = {
	status: PowerGaugeStatus,
	rotation?: number,
}

function Dial({ status, rotation }: Props) {
	const Icon = iconMap[status] || MehFace
	return (
		<StyledSvg
			$rotation={rotation}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 134.476 166.674">
			<g transform="translate(-794.45 -850.183) rotate(31)">
				<path
					d="M0,68.324a11.986,11.986,0,0,1,.873-4.49q.017-.132.042-.265L11.818,6.9a4,4,0,0,1,7.856,0l10.9,56.671q.026.133.042.265a11.987,11.987,0,0,1,.873,4.491c0,7.534-7.05,13.641-15.746,13.641S0,75.857,0,68.324Z"
					transform="translate(1161.914 289.443) rotate(-30)"
				/>
				<circle cx="49" cy="49" r="49" transform="translate(1178.701 321.18)" />
			</g>
			<g transform="translate(25, 16)">
				<Icon width="84" />
			</g>
		</StyledSvg>
	)
}

const StyledSvg = styled.svg`
	fill: white;
	margin: 0 auto;
	width: 25%;
	transform: rotate(${props => getRotation(props.$rotation)}deg);
	transform-origin: center 60%;
	height: auto;
	transition: all 0.4s ease-in-out;
`

/**
 * Since the dial svg points to the 12'clock position,
 * we rotate the dial to the far left (9'clock) or starting position by subtracting 90 degrees
 * @param {number} value of rotation
 */
function getRotation(value: number): number {
	return -90 + value
}

export default (memo<Props>(Dial): AbstractComponent<Props>)
