import type { StationEnum } from './types/index'

const runningOnMissionIO = window.location.hostname.endsWith('.mission.io')
const domainName = runningOnMissionIO ? 'mission.io' : 'infinid.io'

// TODO[rebrand] this can be removed once we stop support infinid.io
if (!runningOnMissionIO) {
	const faviconElement = document.querySelector("link[id='favicon']")
	if (faviconElement?.tagName === 'LINK') {
		// $FlowIgnore[prop-missing] this is a link tag so it has an href attribute
		faviconElement.href = `https://resources-cdn.mission.io/public/logos/infinid-icon-standard.png`
	}

	document.title = 'Infini-D: Join Mission'
}

type Config = {
	// The environment the app is running in
	environment: 'production' | 'staging' | 'development',
	webSocketServerUrl: string,
	missionServerUrl: string,
	missionLoginUrl: string,
	loginServerUrl: string,
	loginUrl: string,
	companyMarketingWebsiteUrl: string,
	companyName: string,
	// Whether to track errors in our error reporting tools (Sentry, LogRocket)
	trackErrors: boolean,
	//
	+sentry: {
		+dsn: string,
		replaysSessionSampleRate: number,
		replaysOnErrorSampleRate: number,
	},
	logRocketUrl?: string,
	promoteFreeVersion: boolean,
	isProd: boolean,
	isStaging: boolean,
	matchmakerUrl: string,
	isDev: boolean,
	useMatchmaker: boolean,
	useTwilio: boolean,
	useGoogleTranslate: boolean,
	websocketCookieName: string,
	runningOnMissionIO: boolean,
	studentLoginDomain: string, // The string to display on mission player for student login
	serverCookieName: string,
	sessionStorageStudentIdKey: string,
	localStorageJoinPreviousMissionKey: string,
	googleTranslateCookieDomains: string[],
	useTeacherCardSignIn: boolean,
	dashboardUrl: string,
	featureFlags: {|
		createNewStudent: boolean,
		literacyEvents: boolean,
		useCloseReadingTask: boolean,
	|},
	devFlags?: {
		stationDevIsWorkingOn?: StationEnum,
		hidePauseScreenOverlay?: boolean,
		hideFullscreenHeader?: boolean,
		hideSentryErrorFallbackPage?: boolean,
	},
}

const config: Config = {
	environment: 'development',
	webSocketServerUrl: '',
	missionServerUrl: '',
	loginServerUrl: runningOnMissionIO
		? `https://login-server.${domainName}`
		: `https://server.${domainName}`,
	companyMarketingWebsiteUrl: runningOnMissionIO
		? 'https://mission.io'
		: 'https://infinidlearning.com',
	loginUrl: `https://login.${domainName}`,
	missionLoginUrl: runningOnMissionIO ? `https://launch.${domainName}` : `https://${domainName}`,
	companyName: runningOnMissionIO ? 'Mission.io' : 'Infini-D Learning',
	promoteFreeVersion: false,
	isProd: false,
	isStaging: false,
	matchmakerUrl: '',
	isDev: false,
	useTwilio: false,
	useMatchmaker: process.env.REACT_APP_USE_MATCHMAKER === 'true',
	useGoogleTranslate: true,
	websocketCookieName: 'websocketSessionId',
	runningOnMissionIO,
	studentLoginDomain: domainName,
	serverCookieName: 'infinid-server.sid',
	sessionStorageStudentIdKey: 'STUDENT_ID',
	localStorageJoinPreviousMissionKey: 'PREVIOUS_MISSION_DATA',
	googleTranslateCookieDomains: [`.${domainName}`],
	useTeacherCardSignIn: true,
	dashboardUrl: `https://dashboard.${domainName}`,
	featureFlags: {
		createNewStudent: true,
		literacyEvents: true,
		useCloseReadingTask: true,
	},
	sentry: {
		dsn: 'https://12f05da0f2bf406a8448581fc23fdb66@o185870.ingest.us.sentry.io/1531034',
		// record 1 of every 500 sessions
		replaysSessionSampleRate: 1 / 500,
		// record every time there's an error
		replaysOnErrorSampleRate: 1.0,
	},
	trackErrors: true,
}

if (process.env.REACT_APP_ENV === 'staging') {
	config.environment = 'staging'
	config.webSocketServerUrl = config.useMatchmaker
		? ''
		: `wss://staging-mission-server.${domainName}/ws`
	config.isProd = true
	config.isStaging = true
	config.logRocketUrl = 'ofq7fy/jr-controls-prod'
	config.matchmakerUrl = `https://staging-matchmaker.${domainName}`
	config.missionServerUrl = `https://staging-mission-server.${domainName}`
	config.loginServerUrl = `https://staging-server.${domainName}`
	config.missionLoginUrl = `https://staging.launch.${domainName}`
	config.loginUrl = `https://staging.login.${domainName}`
	config.useTwilio = true
	config.googleTranslateCookieDomains.push(`staging.${domainName}`)
	config.dashboardUrl = `https://staging.dashboard.${domainName}`
	config.featureFlags.createNewStudent = true
} else if (process.env.NODE_ENV === 'production') {
	config.environment = 'production'
	config.webSocketServerUrl = config.useMatchmaker ? '' : `wss://mission-server.${domainName}/ws`
	config.isProd = true
	config.logRocketUrl = 'ofq7fy/jr-controls-prod'
	config.matchmakerUrl = `https://matchmaker.${domainName}`
	config.missionServerUrl = `https://mission-server.${domainName}`
	config.useTwilio = true
} else {
	config.environment = 'development'
	config.trackErrors = false
	const devHost =
		process.env.REACT_APP_USE_LOCALHOST_DOT_COM === 'true' ? 'localhost.com' : 'localhost'
	config.googleTranslateCookieDomains = [devHost]
	config.missionServerUrl = `http://${devHost}:8000`
	config.missionLoginUrl = `http://${devHost}:8010`
	config.loginServerUrl = `http://${devHost}:3000`
	config.loginUrl = `http://${devHost}:3010`
	config.webSocketServerUrl = config.useMatchmaker ? '' : `ws://${devHost}:8000/ws`
	config.matchmakerUrl = `http://${devHost}:8060`
	config.isDev = true
	config.dashboardUrl = `http://${devHost}:8020`
	// turn on all feature flags in dev
	Object.keys(config.featureFlags).forEach(key => {
		config.featureFlags[key] = true
	})
	config.devFlags = {
		// stationDevIsWorkingOn: 'creativeCanvas',
		hidePauseScreenOverlay: false,
		hideFullscreenHeader: true,
		hideSentryErrorFallbackPage: true,
	}
}

export default config
