import React from 'react'
import Slider from '../Components/Slider'
import { COMPONENT_TYPES } from '../../../../../../store/selectors/jrPlusState/engineering'
import { SecondaryLayer } from '../SharedColors'
import styled from 'styled-components'
import type { SliderData as SliderComponent } from '@mission.io/mission-toolkit'
import type { PopulatedPanel } from '../../../../../../store/selectors/jrPlusState/engineering'

const StyledSliders = styled.div`
	display: flex;
	justify-content: space-around;
`

export default function SwitchPanel({ panel }: { panel: PopulatedPanel }): React$Node {
	let components: { [componentType: string]: SliderComponent[] } = {}
	panel.components.forEach(component => {
		if (component.type !== COMPONENT_TYPES.SLIDER) {
			return
		}
		if (!components[component.type]) {
			components[component.type] = []
		}
		components[component.type].push(component)
	})
	Object.keys(components).forEach(key =>
		components[key].sort((a, b) => a.name.localeCompare(b.name))
	)
	let renderedComponents = []
	Object.keys(components).forEach(type =>
		components[type].forEach(component => {
			renderedComponents.push(<Slider component={component} key={component.id} />)
		})
	)
	return (
		<SecondaryLayer>
			<StyledSliders>{renderedComponents}</StyledSliders>
		</SecondaryLayer>
	)
}
