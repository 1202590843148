import type { StyledType } from '../../../types/utilityTypes'

import styled from 'styled-components'

export const DivWithStyledScroll: StyledType<> = styled.div`
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 8px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background: #364984;
	}
`
